.MyReplie {
  padding: 1em;
  background-color: white;
}

.myReplyTable {
  width: 100%;
  border-collapse: collapse;
}

.myReplyTable thead {
  background-color: #f9f9f9;
}

.myReplyTable th, .myReplyTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.myReplyTable tr:hover {
  background-color: #f0f0f0;
}
