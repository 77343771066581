* {
    text-decoration: none ;
}

a {
    text-decoration: none ;
    color:black;
}

/* .Top3 > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Top3 > div > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Top3 > div > div > img {
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
}

.Top3 > div > div > p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
}

.Top3 > div > div > span {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
}

.Top3 > div > div > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
} */