/* GiftShop.css */

.gifticon-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: calc((100vh - 20px)/5); /* 높이를 화면의 높이에 맞춤 */
  gap: 20px;
  padding: 20px;
  background-color: white;
}

.gifticon-item {
  width: auto; /* 가로 폭을 조정 */
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* 가운데 정렬을 위해 margin을 auto로 설정 */
}

