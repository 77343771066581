.container {
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -3px 20px rgba(255,255,255,0.4);
}

.point_th,
.point_data {
  padding: 20px; /* Adjust the padding as needed */
  font-size: larger;
}

.point_table {
    width:100%;
    border: #666;
    border-collapse: separate;
    border-spacing: 0rem;
    font-size: 15px;
    margin: 0; /* Remove or adjust as needed */

}

.point_data {
    margin-top: 100px;
}

.btn_div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px auto; /* Adjusted margin */
  }

.point_data {
    margin-top: 10px;
}

.point_btn {
    width: 13vh;
    background-color: rgb(152, 179, 228);
    color: black;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;
    max-width: 25vh;
    font-size: 15px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  @media screen and (min-width: 1000px) {
    .point_btn {
      margin-top: 3%;
    }
  }

    
.point_btn:hover {
    background-color: #1b497acc;
}

.point_top {
    background-color: rgba(250, 199, 199, 1);
    padding-bottom: 1%;
    
}

.info {
    margin: 0 auto;
    margin-top:20px;
    margin-bottom:20px;
    width: 80%;
    height: 100%;
    background-color: rgba(250, 199, 199, 1);
    border-radius: 15px;
    padding: 10px;
}

.now_point {
    position: relative;
    top: 15px;
    font-size:20px;
    margin: auto;
    margin-top:20px;
    margin-bottom:20px;
    width: 80%;
    height: 100%;
    background-color: rgba(250, 199, 199, 1);
    border-radius: 15px;
    padding: 10px;
    /* 텍스트 그림자 애니메이션 추가 */
}

.point_tableDiv {
    height: 50vh;
    overflow-y: auto;
    margin: 0;
}

::-webkit-scrollbar {
    width: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }