.AdminMemberTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .AdminMemberTable thead {
    background-color: #f9f9f9;
  }
  
  .AdminMemberTable th, .AdminMemberTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .AdminMemberTable tr:hover {
    background-color: #f0f0f0;
  }
  