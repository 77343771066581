/* 마이페이지 전체 영역 스타일링 */
.Mypage {
    margin: 0 auto; /* 페이지 중앙 정렬 */
    padding: 3em; /* 내부 요소와의 간격 설정 */
    background-color: #ffffff; /* 배경색 설정 */
}

/* 제목 영역 스타일링 */
.Mypage_title {
    display: flex; /* 요소를 가로로 배열 */
    justify-content: space-between; /* 요소를 양쪽 끝으로 분배 */
    padding: initial; /* 내부 요소와의 간격 설정 */
    border-bottom: 10px solid lightblue; /* 테두리 설정 */
}

/* 제목 텍스트 영역 스타일링 */
.Mypage_title_text {
    display: flex; /* 요소를 가로로 배열 */
    flex-direction: column; /* 요소를 세로로 배열 */
    justify-content: center; /* 요소를 세로 중앙 정렬 */
}

/* 프로필 이미지 영역 스타일링 */
.Mypage_title_img {
    width: 100px; /* 이미지 너비 설정 */
    height: 100px; /* 이미지 높이 설정 */
    border-radius: 50%; /* 이미지를 원형으로 만듦 */
    margin-right: 2em; /* 오른쪽 마진 설정 */
    margin-bottom: 100px;
}

.Mypage_title_point {
    flex-direction: column; /* 요소를 세로로 배열 */
    display: flex; /* Flexbox 레이아웃 사용 */
    justify-content: center; /* 요소를 세로 중앙 정렬 */
    text-align: right; /* 텍스트를 오른쪽으로 정렬 */
}

/* 정보 영역 스타일링 */
.Mypage_info {
    padding: 1em; /* 내부 요소와의 간격 설정 */
    text-align: left;
}

/* 정보 텍스트 스타일링 */
.mypage_text {
    margin-top: 2em;
    margin-bottom: 1.2em; /* 아래쪽 마진 설정 */
    color: #666; /* 텍스트 색상 설정 */
    font-size: 1.3em; /* 폰트 크기 설정 */
}

/* 프로필 이미지 스타일링 */
.profile_img {
    width: 100%; /* 이미지 너비 설정 */
    height: 100%; /* 이미지 높이 설정 */
    object-fit: cover; /* 이미지를 영역에 맞게 자동 조절 */
    border-radius: 50%;
}

/* 포인트 텍스트 스타일링 */
.point_history {
    font-size: 15pt; /* 폰트 크기 설정 */
}

/* 닉네임 텍스트 스타일링 */
.nickname {
    font-size: 1.5em; /* 폰트 크기 설정 */
    font-weight: bold; /* 폰트 굵기 설정 */
    color: #333; /* 폰트 색상 설정 */
}
/* 아이콘 그룹 스타일링 */
.icon_group {
    display: flex; /* 요소를 가로로 배열 */
    align-items: center; /* 요소를 가운데 정렬 */
    margin-bottom: 2.5em; /* 아래쪽 마진 설정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
    border-bottom: 1px solid #e6e6e6; /* 테두리 설정 */

}

/* 아이콘 이미지 스타일링 */
.icon_img {
    width: 25px; /* 이미지 너비 설정 */
    height: 25px; /* 이미지 높이 설정 */
    margin-right: 1.2em; /* 오른쪽 마진 설정 */
}

.icon_text {
    font-size: 1.2em;
    margin-top: 30px;
}
.icon_group span {
    font-size: 20px; /* 텍스트 크기 조정 */
}

