.points-container {
    width: 100%;
    max-width: 350px;
    margin: auto;
    padding: 20px;
    margin-top: 20px;
  }
  
  .title {
    text-align: center;
  }
  
  .user-info {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
  }
  
  .user-name {
   font-size:18px; 
   font-weight:bold; 
   color:#333; 
   margin-bottom:10px; 
  }
  
  .user-points {
   font-size:16px; 
   color:#666; 
  }
  