.nav_bar {
    position:relative;
    width: auto;
    height: 55px;
    /* background-color: #c2c2c2; */
    margin: 0;
}

.Logo {
    margin-top: 1px;
    position: absolute;
    left:1.5%;
    margin: 0px 0px 0px 0px;
    width: 60px;
    height: auto;
}

.nav {
    display: flex;
    width: 100%;
    /* background-color: #c2c2c2; */
    padding: 15px 0px;
    text-align: right;
    justify-content: space-around;
}

.NavMenu {
    color: #000000;
    margin: 5px;
    text-decoration: none;
}



.Main_text { /* 상단 메인 텍스트 */
    margin-top:10px;
    padding:0;
    width: 180px;

}

.main_text h2 a {
    text-decoration: none;
    color: #000000;
}


/* 햄버거 메뉴 */
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}
/* 기존 스타일 코드... */

.hamburger_menu {
    position: absolute;
    top: 0; /* 수정: 화면 상단에 고정 */
    bottom: 0; /* 추가: 화면 하단에 고정 */
    right: 0; /* 수정: 화면 오른쪽에 고정 */
    background-color: rgb(71, 71, 72); /* 배경색 변경 */
    animation: fadeInRight 1.5s;
    z-index: 9999;
}

  

  .hamburger_menu ul {
    list-style-type: none;
    border-radius: 5px;
    padding-left: 0px;
    padding-top: 0px;
  }
  
  .hamburger_menu ul li {
     /* padding-top: 10px;
     padding-right: 20px;
     padding-left:20px;
    padding-bottom: 10px; */
    /* width: 20vh; */
    margin-left: 4vh;
    margin-right: 4vh;
    height: 100%;
    padding:20px;
    font-size: 20px;
    text-align: left;
    color:rgb(254, 0, 0); /* 링크 색상 설정 */
  }
  
  .hamburger_menu ul li a {
     text-decoration:none; /* 링크 밑줄 제거 */
     color:rgb(255, 255, 255); /* 링크 색상 설정 */
  }

/* 프로필 이미지와 이름이 들어갈 li 태그 */
.hamburger_menu ul .profile {
    margin-top: 30px;
    display: flex;
    flex-direction: column; /* 추가: 요소들을 수직으로 배치 */
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
    background-color: rgb(71, 71, 72);/* 배경색 추가 */
    color: #fffdfd; /* 글자 색상 추가 */
    border-bottom: #fffdfd solid 1px;
}


/* 프로필 이미지 */
.hamburger_menu ul .profile img {
    width: 70px; /* 이미지 크기 조정 */
    height:70px; /* 이미지 크기 조정 */
    border-radius: 50%; /* 원형으로 만들기 */
    object-fit: cover; /* 이미지 비율 유지 */
    margin-bottom: 13px;
}

/* 프로필 이름 */
.hamburger_menu ul .profile span {
    font-size: 20px; /* 글자 크기 조정 */
    font-weight: bold; /* 글자 두께 조정 */
    text-align: center; /* 추가: 이름을 가운데 정렬 */

}

/* styles.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* 어두운 배경 색상 및 투명도 */
    z-index: 999; /* 메뉴 위에 오도록 설정 */
    opacity: 0; /* 초기에는 보이지 않게 */
    transition: opacity 0.5s ease-in-out; /* 트랜지션 애니메이션 적용 */
    pointer-events: none; /* 메뉴가 열릴 때만 이벤트 활성화 */
  }
  
  .search input {
    width: 70%;
    height: 20px;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #000000;
    box-shadow: 0px 0px 5px 0px #353535;
    padding: 5px;
    margin: 10px;
    margin-right: 0;
}

.search button {
    border: none;
    border-radius : 0px 5px 5px 0px;
    height:31px;
    width: 30px;
    padding:5px;
    padding-top: 1px;
}

.search button img {
    height: 20px;
    width: 20px;
}
