.points-container {
    width: 70%;
    margin: 80px auto 0;
    padding: 40px;
    border: 2px solid #ccc;
    border-radius: 10px;
}

.user-name {
    font-size: 24px;
    margin-top: 1px;
}

.input-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
}

.custom-input {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 90%;
}

.custom-input::placeholder {
    color: gray;
}

.custom-input.input-filled::placeholder {
    color: transparent;
}

.clear-button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    background-color: pink;

}

.charge-button-container {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.charge-button {
    background-color: hsl(322, 29%, 87%);
    color: #000000;
    border: none;
    border-radius: 5px;
    padding: 15px 1px;
    font-size: x-large;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 400px;
    margin-bottom: inherit;
}

.charge-button:hover {
    background-color: hsl(318, 79%, 61%);
}

.pay-button-container {
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    border-radius: 10px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
    flex-direction: column;
}

.pay-button-container h3 {
    margin: 0;
    font-size: 18px;
    margin-block: 0;
}

.charge-select {
    justify-content: center;
    margin-top: 20px;


}
.charge-select img:hover {
    opacity: 0.7;
    cursor: pointer;
}

.pay_img {
    width: auto;
    height: 3vh;
    margin: 18px;
}

  
  @keyframes pop {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
    outline: none;
    animation: pop 0.5s;
    text-align: center;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  