/* Hamburger.css */

.hamburger {
    width: 28px;
    height: 25px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .hamburger div {
      width:100%;
      height:3px;
      background-color:rgb(8, 8, 8); 
  }
  
  .hamburger.open div:nth-child(2) {
    opacity:0; 
}
  
.hamburger.open div:nth-child(1) {
    transform : rotate(45deg);
    position:absolute; 
    top :10px; 
}
  
.hamburger.open div:nth-child(3) {
   transform : rotate(-45deg);
   position:absolute; 
   top :10px; 
}