.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.Form {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.Label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

.ContentTextarea {
    width: 99%;
    height: 200px;
    padding: 10px,0;
    border-radius: 10px;
    border: 2px solid #ddd;
    resize: none;
}

.DropdownContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.Select {
    width: 70px; /* Adjust the width as needed */
    height: 40px;
    padding: 8px;
    border-radius: 0px;
    font-size: 16px;
}

/* Add this style to adjust the width for each Select */
.Select:nth-child(2),
.Select:nth-child(3) {
    width: 120px; /* Adjust the width as needed */
}



.Textarea {
    width: 100%;
    padding: 10px 0;
    border-radius: 8px;
    border: 2px solid #ddd;
}

.TextareaSmall {
    width: 92%;
    padding: 5px;
    border-radius: 8px;
    border: 2px solid #ddd;
}

.ButtonContainer {
    display: flex;
    flex-direction: row;
}
    

.ButtonContainer p {
    font-size: 12px;
    margin:0;
}

.AddButton, .DelButton {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    background-color: #52ffeb;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
}

.button-2 {
    margin-top: 10px;
    width: 30%;
    height: 45px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    }

    .button-2:hover {
        background-color: #2EE59D;
        box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
        color: #fff;
        transform: translateY(-7px);
      }
  
  .button-1 {
    width: 45px;
    height: 45px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    }
  
  .button-1:hover {
    background-color: #2EE59D;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }

.SelectContainer {
    width: 99%;
    display: flex;
    flex-direction: column;
}

.SelectContainer::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px; /* 선의 높이 조절 */
    background-color: pink; /* 선의 색상 조절 */
    margin: 10px 0; /* 선 위아래 간격 조절 */
}

.SelectContainer label textarea {
    border-radius: 10px;
    border: 2px solid #ddd;
    resize: none;
    width: 100%;
    margin-bottom: 4px;
}

.FunctionContainer {
    display: flex;
    align-items: center;
    flex-direction: row;

}

.ImgLabel {
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    background-color: #fffefe;
    width: 45px;
    height: 45px;
    border-radius: 45px;

}

.ImgLabel input{
    display: none;
}

.ImgLabel p {
    margin: 0;
    font-size: 10px;
    color: #333;
    font-weight: bold;
}

.FunctionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.CategoryContainer {
    display: flex;
    align-items: center;
    height: 40px;
    flex-direction: row;
    width: 100%;
}

.TitleInput {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}